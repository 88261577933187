<template>
  <div class="progress-wrap">
    <div class="progress">
      <div class="title">Your Money Matters. Let Us Safeguard it For You.</div>
      <div class="box">

        <div class="content-wrap">
          <div class="content">

            <div class="item">
              <div class="desc">
                <div class="desc-title">Robust <br> Dispute Resolution</div>
                <div class="line" />
                <div class="desc-content">If you’re not happy with your purchase, we’ll withhold your seller’s funds and mediate disagreements until all issues are resolved.</div>
              </div>
            </div>

            <div class="item">

              <div class="desc">
                <div class="desc-title">100% of Your Money Back - No Strings Attached</div>
                <div class="line" />
                <div class="desc-content">We’ll make sure you get the amount refunded for, including any platform fees.</div>
              </div>
            </div>

            <div class="item">

              <div class="desc">
                <div class="desc-title">60-day Coverage for Unauthorized Transactions</div>
                <div class="line" />
                <div class="desc-content">Noticed a transaction you didn’t make? You have all the time you need to raise any unauthorised transaction dispute with us.</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.progress-wrap{
  background: linear-gradient(285.74deg,#0a2533,#004b66 58.63%,#1b8ab3 114.88%);
  .progress{
    div{
      box-sizing: border-box;
    }
        padding: 24px 32px;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
      @media (min-width: 768px) {

      padding: 64px 96px;
        }
      @media (min-width: 1280px) {
              max-width: 1280px;
      padding: 64px 96px;
      }
      .title{
        padding-top: 2rem;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #fff;
        letter-spacing: -1px;
        @media (min-width: 768px) {
          font-size: 36px;
          line-height: 48px;
        }
      }
      .box{
        .content-wrap{
          position: relative;
          margin-top: 0px;
              .content{
                // gap: 32px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                @media (min-width: 768px) {
                  flex-direction: column;
                  gap: 0;
                }
                @media (min-width: 1280px) {
                  gap: 1rem;
                  flex-direction: row;
                }
                .item{
                  min-width: 328px;
                  padding: 24px;
                  border-radius: 0.75rem;
                  gap: 12px;
                  display: flex;
                  flex: 1 1 0%;
                  .img{
                      display: none;
                      height: auto;
                      max-width: 100%;
                  }
                  .desc{
                        gap: 0.5rem;
                        display: flex;
                        flex-direction: column;
                        .desc-title{
                          line-height: 30px;
                          font-size: 20px;
                          font-weight: 600;
                          color: #fff;
                        }
                        .line{
                          background: linear-gradient(90deg,#b2f000,rgba(178,240,0,0));
                          width: 40px;
                          height: 4px;
                        }
                        .desc-content{
                          line-height: 24px;
                          padding-top: 8px;
                          font-size: 16px;
                          font-weight: 400;
                          color: #fff;

                        }

                  }
                }
              }

        }
      }

  }
}

</style>
