<template>
  <div class="progress">
    <div class="title">
      How Buyer Protection Works
    </div>
    <div class="box">
      <div class="top">
        <img src="@/assets/feedback/top-img.svg" alt="Buyer Protection Graphic" class="top-img">
        <div class="pointer-wrap">
          <img src="@/assets/feedback/poninter1.svg">
          <img src="@/assets/feedback/poninter2.svg">
          <img src="@/assets/feedback/poninter3.svg">
        </div>
      </div>

      <div class="content-wrap">
        <div class="content">

          <div class="item">
            <img src="@/assets/feedback/order1.svg" class="img">
            <div class="desc">
              <div class="desc-title">Make your purchase</div>
              <div class="desc-content">Buyer pays  and We verifies the necessary documents before releasing payment to the seller. Buyer automatically becomes eligible for buyer protection.</div>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/order2.svg" class="img">
            <div class="desc">
              <div class="desc-title">Submit a claim</div>
              <div class="desc-content">If the customer does not receive the goods or services, the customer drops an email to
                <a href="mailto:customer.support@aftersale.pro" type="email" class="text-countBlue">
                  customer.support@aftersale.pro
                </a>
                with the transaction ID and a description of the transaction</div>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/order3.svg" class="img">
            <div class="desc">
              <div class="desc-title">Dispute resolution and refund</div>
              <div class="desc-content">We reviews the evidence submitted by the buyer and seller to mediate a resolution</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.progress{
  div{
    box-sizing: border-box;
  }
    background: #fff;
    padding:20px;
          max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  @media (min-width: 768px) {
    padding: 6rem 2rem;
    }
  @media (min-width: 1280px) {
          max-width: 1280px;
  }
    .title{
      font-size: 24px;
      line-height: 36px;
      padding-top: 20px;
      padding-bottom: 20px;
  @media (min-width: 768px) {
      font-size: 36px;
      line-height: 43px;
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
      text-align: center;
      color: rgba(38,83,107, 1);
      letter-spacing: -1px;
    }
    .box{
      .top{
          position: relative;
          z-index: 1;
        .top-img{
          margin: 0 auto;
          @media (min-width: 768px) {
            padding-left: 124px;
            padding-right: 124px;
              display: none;
            }
          @media (min-width: 1280px) {
                  max-width: 1280px;
                   display: block;
          }
          // position: relative;
          // z-index: 1;
            padding-left: 176px;
            padding-right: 176px;
                height: auto;
    max-width: 100%;

    vertical-align: middle;
        }
        .pointer-wrap{
          padding-left: 160px;
          padding-right: 160px;
          display: none;
          @media (min-width: 768px) {
              padding-left: 100px;
              padding-right: 100px;
              gap: 20px;
              display: none;
            }
            @media (min-width: 1280px) {
              padding-left: 160px;
              padding-right: 160px;
               display: flex;
              gap: 0px;
            }

        justify-content: space-between;

        img{
              height: auto;
    max-width: 100%;
        }
        }
      }
      .content-wrap{
        position: relative;
            top: -2rem;
            .content{
              gap: 32px;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              @media (min-width: 768px) {
                flex-direction: column;
                gap: 0;
              }
              @media (min-width: 1280px) {
                gap: 1rem;
                flex-direction: row;
              }
              .item{
                margin-top: 20px;
                box-shadow: 0 2px 4px rgba(226,232,240,.24), 0 4px 6px rgba(226,232,240,.5);
                background: linear-gradient(180deg,#e4f8ff -53.32%,#fff 52.88%);
                min-width: 328px;
                    width: 100%;
                padding: 56px 24px;
                border-radius: 0.75rem;
                gap: 16px;
                display: flex;
                align-items: flex-start;
                .img{
                      height: auto;
                      max-width: 100%;
                }
                .desc{
                      gap: 0.5rem;
                      display: flex;
                      flex-direction: column;
                      .desc-title{
                        line-height: 28px;
                        font-size: 20px;
                        color: rgba(38,83,107,1);
                        font-weight: 600;
                      }
                      .desc-content{
                        line-height: 24px;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(54,65,82,1);

                      }

                }
              }
            }

      }
    }

}
</style>
