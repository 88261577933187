<template>
  <div class="howto">
    <div class="progress">
      <div class="title">
        How to Raise a Buyer Protection Dispute
      </div>
      <div class="box">

        <div class="content-wrap">
          <div class="content">

            <div class="item-wrap">
              <img src="@/assets/feedback/h1.svg" class="img">
              <div class="item">
                <img src="@/assets/feedback/order1.svg" class="img">
                <div class="desc">
                  <div class="desc-title">Take note of your transaction number</div>
                  <div class="desc-content">The transaction number you fill in must actually exist.</div>
                </div>
              </div>

            </div>

            <div class="item-wrap">
              <img src="@/assets/feedback/h2.svg" class="img">
              <div class="item">
                <img src="@/assets/feedback/order2.svg" class="img">
                <div class="desc">
                  <div class="desc-title">Contact us via our form or email us at <a href="mailto:customer.support@aftersale.pro" type="email" class="text-countBlue">
                    customer.support@aftersale.pro
                  </a></div>
                  <div class="desc-content">Include your transaction number, describe your issue, and attach any supporting documents and photos to the email. Our customer service team will investigate and work with you to come to a resolution.
                  </div>
                </div>
              </div>
            </div>

            <div class="item-wrap">
              <img src="@/assets/feedback/h3.svg" class="img">
              <div class="item">
                <img src="@/assets/feedback/order3.svg" class="img">
                <div class="desc">
                  <div class="desc-title">Hear from the Our team</div>
                  <div class="desc-content">Our customer service team will find a resolution within 14 working days of receiving all the information from the buyer.</div>
                </div>
              </div>
            </div>

            <div class="btn" @click="open">Contact us</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    open() {
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  display: inline;
  display: flex;
  background: linear-gradient(263.11deg,#1fa7d9 .58%,#b2f000 144.39%);
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(226,232,240,.24), 0 4px 6px rgba(226,232,240,.5);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding:14px 8px ;
  border-radius: 6px;
  width: 120px;
  text-align: center;
  justify-content: center;
  margin: 40px auto;
}
.howto {
  background-color: rgba(247, 250, 252, 1);
  .progress {
    div {
      box-sizing: border-box;
    }
    padding: 10px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
       padding: 50px 60px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
       padding: 50px 60px;
    }
    .title {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      padding-top: 20px;
      color: rgba(38, 83, 107, 1);
      letter-spacing: -1px;
      @media (min-width: 768px) {
        font-size: 36px;
        line-height: 43px;
        padding-bottom: 10px;
      }
    }
    .box {
      padding-top: 2.5rem;
      .content-wrap {
        position: relative;
        .content {
          gap: 32px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .item-wrap {
            display: flex;
            // max-width: 365px;
            align-items: center;
            padding:14px;
            box-sizing: border-box;
            justify-content: center;
            flex-direction: column;
            background-image: linear-gradient(
              90deg,
              rgba(88, 197, 237, 0.1),
              hsla(0, 0%, 100%, 0.005)
            );
            @media (min-width: 768px) {
              flex-direction: row;
              gap: 0;
            }
            @media (min-width: 1280px) {
              gap: 1rem;
              flex-direction: row;
            }
            .item {
              min-width: 328px;
              border-radius: 0.75rem;
              gap: 16px;
              display: flex;
              align-items: flex-start;
              flex-direction: row;
              .img {
                height: auto;
                max-width: 100%;
              }
              .desc {
                gap: 0.5rem;
                display: flex;
                flex-direction: column;
                .desc-title {
                  line-height: 24px;
                  font-size: 16px;
                  @media (min-width: 768px) {
                    line-height: 28px;
                    font-size: 20px;
                  }
                  color: rgba(38, 83, 107, 1);
                  font-weight: bolder;
                }
                .desc-content {
                  line-height: 16px;
                  font-size: 14px;
                  @media (min-width: 768px) {
                  line-height: 24px;
                  font-size: 16px;
                  }
                  font-weight: 400;
                  color: rgba(54, 65, 82, 1);
                }
              }
            }
            .img {
              display: block;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

</style>
