var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit"},[_c('div',{staticClass:"title"},[_vm._v("Leave Us a Note")]),_c('div',{staticClass:"sub-title"},[_vm._v("We strive to get back to you in 1-2 business days.")]),_c('a-form',{staticClass:"form",attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"sm":{ span: 12 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"First name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'first_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'first_name',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"sm":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Last name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'last_name',
              {
                rules: [
                  {
                    required: false,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'last_name',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":{ span: 24 },"sm":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Email must be formatted correctly.',
                  },
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [\n                  {\n                    type: 'email',\n                    message: 'Email must be formatted correctly.',\n                  },\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1),_c('a-col',{attrs:{"xs":{ span: 24 },"sm":{ span: 12 }}},[_c('a-form-item',{attrs:{"label":"Country/Region"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'country',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'country',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.countryOption),function(item){return _c('a-select-option',{key:'code' + item.code,attrs:{"value":item.value +'-'+ item.code + '-'+ item.label}},[_c('img',{staticClass:"selectIcon",attrs:{"src":item.logo}}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"sm":{ span: 8 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"Country Calling Code"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'country_code',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'country_code',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.codeOption),function(item){return _c('a-select-option',{key:'key' + item.code,attrs:{"value":item.value +'-'+ item.code + '-'+ item.label}},[_c('img',{staticClass:"selectIcon",attrs:{"src":item.logo}}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"sm":{ span: 16 },"xs":{ span: 24 }}},[_c('a-form-item',{attrs:{"label":"Mobile number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Order No."}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'order_no',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'order_no',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"How can we help you?"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'question_type',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'question_type',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"},on:{"change":_vm.changeQuestionType}},_vm._l((_vm.questionTypeOption),function(item){return _c('a-select-option',{key:'question_type' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),(_vm.question_type === '1')?_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Website URL"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'website_url',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'website_url',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Job title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'job_title',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'job_title',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"What is your existing average monthly transaction volume? (In USD)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'average_monthly_volume',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'average_monthly_volume',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.averageMonthlyVolumeOptions),function(item){return _c('a-select-option',{key:'issue' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Are your transactions local or international? (We can't support domestic India and UAE Transactions)"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'local_or_international',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'local_or_international',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.localOrInternationalOptions),function(item){return _c('a-select-option',{key:'issue' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"How did you hear about us?"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'how_to_know',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'how_to_know',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Company Industry"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'company_industry',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'company_industry',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.companyIndustryOptions),function(item){return _c('a-select-option',{key:'issue' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1)],1):(_vm.question_type === '2')?_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"What issue are you facing?"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'issue',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'issue',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.issueOptions),function(item){return _c('a-select-option',{key:'issue' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Transaction number (Optional)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'transaction_number',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'transaction_number',\n                {\n                  rules: [\n                    {\n                      required: false,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1)],1):(_vm.question_type === '3')?_c('div',[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Which Integration Method Are You Using?"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'integration',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]),expression:"[\n                'integration',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please complete this required field.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},_vm._l((_vm.integrationOptions),function(item){return _c('a-select-option',{key:'question_type' + item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1)],1):_c('div',[_vm._v("   ")]),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Subject"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'subject',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'subject',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"Your Message"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'message',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'message',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large","auto-size":{ minRows: 2, maxRows: 4 }}})],1)],1)],1),_c('a-row',{staticClass:"desc"},[_c('a-col',[_vm._v(" From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below. ")])],1),_c('a-row',{staticClass:"desc",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'agree',
              {
                rules: [
                  {
                    // eslint-disable-next-line vue/this-in-template
                    validator: this.readIt,
                    message: 'Please complete this required field.',
                  },
                ],
              },
            ]),expression:"[\n              'agree',\n              {\n                rules: [\n                  {\n                    // eslint-disable-next-line vue/this-in-template\n                    validator: this.readIt,\n                    message: 'Please complete this required field.',\n                  },\n                ],\n              },\n            ]"}],staticClass:"feedback_input",attrs:{"size":"large"}},[_c('span',{staticClass:"desc"},[_vm._v(" I agree to receive other communications.")])])],1)],1)],1),_c('a-row',{staticClass:"desc"},[_c('a-col',[_vm._v(" By continuing, you agree to our Terms of Use & Privacy Policy notice, in particular the purposes for which your personal data is collected. ")])],1),_c('a-form-item',{staticClass:"confirmContainer"},[_c('a-button',{staticClass:"goToBtn",attrs:{"html-type":"submit"}},[_vm._v(" Confirm ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }