<template>
  <div class="buyerBG">
    <div class="container">
      <div class="content">
        <div class="desc">
          <div class="title">Get What You Paid For With Buyer Protection</div>
          <div class="detail">
            <div class="p1">Making purchases online for goods always comes with risks </div>
            <div class="p2">Let us protect your payments through escrow</div>
          </div>
        </div>
        <img src="@/assets/feedback/intro.svg" alt="Intro Logo" class="img">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.buyerBG{
  background: linear-gradient(95.32deg,#0a2533 -9.91%,#004b66 50.16%,#1b8ab3 107.78%);
  div{
    box-sizing: border-box;
  }
  .container{
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 28px;
    @media (min-width: 1280px) {
      max-width: 1280px;
      padding-top: 128px;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @media (min-width: 768px) {
      padding-left: 4rem;
      padding-top: 128px;
      padding-right: 0;
      padding-top: 138px;
    }
    .content{
      display: flex;
      gap: 10px;
      flex-direction: column;
      @media (min-width: 768px) {
        flex-direction: row;
        gap: 51px;
      }
      @media (min-width: 1280px) {
        gap: 1rem;
        flex-direction: row;
      }
      .desc{
        .title{
              @media (min-width: 768px) {
                font-size: 60px;
                line-height: 80px;
                padding-bottom: 10px;
              }
              font-size: 24px;
              color: #fff;
              font-weight: 600;
              line-height: 30px;
              padding-bottom: 20px;
        }
        .detail{
          .p1{
            color: rgba(178,240,0,1);
            font-size: 16px;
            line-height: 18px;
            padding-bottom: 10px;
              @media (min-width: 768px) {
                font-size: 24px;
                padding-bottom: 10px;
                line-height: 36px;
              }
          }
          .p2{
                font-weight: 400;
                color: #fff;
                padding-bottom: 20px;
                font-size: 12px;
              @media (min-width: 768px) {
                font-size: 16px
              }
          }
        }

      }
      .img{
        display: block;
        height: auto;
        max-width: 100%;
        max-width: 5rem;
        // margin: 0 auto;
        @media (min-width: 1280px) {
          max-width: none;
        }
        @media (min-width: 768px) {
          max-width: 24rem;
        }
      }
    }
  }
}
</style>
