<template>
  <div class="condition-wrap">
    <div class="condition">
      <div class="box">
        <div class="left-wrap">
          <div class="card-wrap">
            <div class="card">
              <div class="title">Who's Eligible for Our Buyer Protection?</div>
              <div class="right-list">
                <div class="right-item">
                  <img src="@/assets/feedback/nice.svg" alt="">

                  <div>You didn’t receive your purchased items</div>
                </div>
                <div class="right-item">
                  <img src="@/assets/feedback/nice.svg" alt="">

                  <div>The items are significantly different from what’s described</div>
                </div>
              </div>
            </div>
          </div>
          <div class="desc">If during the course of Our investigation we find enough evidence of fraudulent activities by the seller, we will exercise legal options and take necessary steps under the law.</div>
          <div class="btn" @click="open">Contact us</div>
        </div>
        <div class="right-wrap">
          <div class="title">
            Buyer Protection Does Not Apply for…

          </div>

          <div class="item">
            <img src="@/assets/feedback/close.svg" alt="">
            <div>Custom-made goods that are significantly not as described</div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/close.svg" alt="">
            <div>Used goods</div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/close.svg" alt="">
            <div>Items that violate our policies</div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/close.svg" alt="">
            <div>Disputes filed more than 180 days after the purchase for item not received and significantly not as described claims</div>
          </div>

          <div class="item">
            <img src="@/assets/feedback/close.svg" alt="">
            <div>Unauthorized transaction claims reported more than 60 days after the transaction date</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    open() {
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.condition-wrap{
    background-color: rgba(247,250,252,1);
    background-image: url('~@/assets/feedback/condition-bg.svg');
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
.condition{
    padding: 24px;
    margin:0 auto;
    max-width: 1024px;
    @media (min-width: 768px) {
        padding: 6rem 4rem;
    }
    @media (min-width: 1280px) {
       padding: 6rem 4rem;
    }
  .box{
    display: flex;
    // flex-direction: row;
    gap: 2rem;
    flex-direction: column-reverse;
    @media (min-width: 768px) {
      flex-direction: row;
      gap: 20px;
    }
    @media (min-width: 1280px) {
      gap: 1rem;
      flex-direction: row;
    }
    .left-wrap{
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      .card-wrap{
        background: linear-gradient(91.48deg,rgba(96,242,242,.1),hsla(0,0%,100%,.005) 101.91%),#fff;
        background-clip: content-box,border-box;
        background-image: linear-gradient(#fff,#fff),linear-gradient(90deg,#b2f000,#1fa7d9);
        background-origin: border-box;
        border-color: transparent;
        box-shadow: 0 10px 15px rgba(39,48,63,.2);
        border-width: 4px;
        border-style: solid;
        border-radius: 0.75rem;

        .card{
          gap: 2rem;
          padding: 2rem;
          .title{
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            color: rgba(38,83,107,1);
            @media (min-width: 768px) {
                font-size: 30px;
                line-height: 40px;
            }
          }
          .right-item{
              display: flex;
              align-items: flex-start;
              gap: 10px;
              margin-top: 10px;
          }
        }
      }
      .desc{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: justify;
      }
      .btn{
        display: inline;
        display: flex;
        background: linear-gradient(263.11deg,#1fa7d9 .58%,#b2f000 144.39%);
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(226,232,240,.24), 0 4px 6px rgba(226,232,240,.5);
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding:16px 12px ;
        border-radius: 6px;
        width: 150px;
        text-align: center;
        justify-content: center;
      }
    }
    .right-wrap{
      padding: 2rem;
      background-color: rgba(255,255,255,1);
      border-radius: 0.75rem;
      gap: 15px;
      display: flex;
      flex-direction: column;
      // padding-top: 20px;;
      .title {
        line-height: 36px;
        font-size: 24px;
        color: rgba(38,83,107,1);
        font-weight: 600;
        padding-bottom: 20px;;
        @media (min-width: 768px) {
          line-height: 40px;
          font-size: 30px;
        }
      }
      .item{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        img{
          margin-top: 2px;
        }
      }
    }
  }
}
}

</style>
