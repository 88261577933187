<template>
  <div>
    <Header @open="open" />
    <Banner />
    <HowTo @open="open" />
    <Progroess />
    <Condition @open="open" />
    <a-modal
      v-model="withdrawVisible"
      title=""
      :footer="null"
      :mask-closable="false"
      centered
      width="800px"
    >
      <Submit @close="close" />
    </a-modal>

    <ForYou />

    <Footer />

  </div>
</template>
<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Banner from './components/Banner.vue'
import Progroess from './components/Progroess.vue'
import Condition from './components/Condition.vue'
import Submit from './components/Submit.vue'
import ForYou from './components/ForYou.vue'
import HowTo from './components/HowTo.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Banner,
    Progroess,
    Condition,
    Submit,
    ForYou,
    HowTo
  },
  data() {
    return {
      withdrawVisible: false
    }
  },
  created() {
    document.title = 'Get What You Paid For With Buyer Protection'
  },
  methods: {
    open() {
      this.withdrawVisible = true
    },
    close() {
      this.withdrawVisible = false
    }
  }
}

</script>
