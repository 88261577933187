<template>
  <footer class="footer">
    <span>© 2024. All right reserved.</span>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
  padding: 16px 64px;
  background-color: rgba(255,255,255,1);
  border-top-width: 1px;
  border-color: rgba(207,216,227, 1);
  color: rgba(38,83,107,1);
  font-size: 14px;
  text-align: center;
}
</style>
