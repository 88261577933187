<template>
  <div class="submit">
    <div class="title">Leave Us a Note</div>
    <div class="sub-title">We strive to get back to you in 1-2 business days.</div>
    <a-form layout="vertical" class="form" :form="form" @submit="handleSubmit">

      <a-row :gutter="24">
        <a-col :sm="{ span: 12 }" :xs="{ span: 24 }">
          <a-form-item label="First name">
            <a-input
              v-decorator="[
                'first_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }">
          <a-form-item label="Last name">
            <a-input
              v-decorator="[
                'last_name',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }">
          <a-form-item label="Email">
            <a-input
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      type: 'email',
                      message: 'Email must be formatted correctly.',
                    },
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="{ span: 24 }" :sm="{ span: 12 }">
          <a-form-item label="Country/Region">
            <a-select
              v-decorator="[
                'country',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            >
              <a-select-option
                v-for="item of countryOption"
                :key="'code' + item.code"
                :value="item.value +'-'+ item.code + '-'+ item.label"
              >
                <img class="selectIcon" :src="item.logo"> {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :sm="{ span: 8 }" :xs="{ span: 24 }">
          <a-form-item label="Country Calling Code">
            <a-select
              v-decorator="[
                'country_code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            >
              <a-select-option
                v-for="item of codeOption"
                :key="'key' + item.code"
                :value="item.value +'-'+ item.code + '-'+ item.label"
              >
                <img class="selectIcon" :src="item.logo"> {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :sm="{ span: 16 }" :xs="{ span: 24 }">
          <a-form-item label="Mobile number">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="24">
          <a-form-item label="Order No.">
            <a-input
              v-decorator="[
                'order_no',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="24">
          <a-form-item label="How can we help you?">
            <a-select
              v-decorator="[
                'question_type',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
              @change="changeQuestionType"
            >
              <a-select-option
                v-for="item of questionTypeOption"
                :key="'question_type' + item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <div v-if="question_type === '1'">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Website URL">
              <a-input
                v-decorator="[
                  'website_url',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Job title">
              <a-input
                v-decorator="[
                  'job_title',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="What is your existing average monthly transaction volume? (In USD)">
              <a-select
                v-decorator="[
                  'average_monthly_volume',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              >
                <a-select-option
                  v-for="item of averageMonthlyVolumeOptions"
                  :key="'issue' + item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Are your transactions local or international? (We can't support domestic India and UAE Transactions)">
              <a-select
                v-decorator="[
                  'local_or_international',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              >
                <a-select-option
                  v-for="item of localOrInternationalOptions"
                  :key="'issue' + item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="How did you hear about us?">
              <a-input
                v-decorator="[
                  'how_to_know',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Company Industry">
              <a-select
                v-decorator="[
                  'company_industry',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              >
                <a-select-option
                  v-for="item of companyIndustryOptions"
                  :key="'issue' + item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

      </div>
      <div v-else-if="question_type === '2'">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="What issue are you facing?">
              <a-select
                v-decorator="[
                  'issue',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              >
                <a-select-option
                  v-for="item of issueOptions"
                  :key="'issue' + item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Transaction number (Optional)">
              <a-input
                v-decorator="[
                  'transaction_number',
                  {
                    rules: [
                      {
                        required: false,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div v-else-if="question_type === '3'">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Which Integration Method Are You Using?">
              <a-select
                v-decorator="[
                  'integration',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please complete this required field.',
                      },
                    ],
                  },
                ]"
                class="feedback_input"
                size="large"
              >
                <a-select-option
                  v-for="item of integrationOptions"
                  :key="'question_type' + item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <div v-else>
        &nbsp;
      </div>

      <a-row :gutter="24">
        <a-col :span="24">
          <a-form-item label="Subject">
            <a-input
              v-decorator="[
                'subject',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24">
        <a-col :span="24">
          <a-form-item label="Your Message">
            <a-textarea
              v-decorator="[
                'message',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
              :auto-size="{ minRows: 2, maxRows: 4 }"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="desc">
        <a-col>
          From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below.
        </a-col>
      </a-row>

      <a-row :gutter="24" class="desc">
        <a-col :span="24">
          <a-form-item label="">
            <a-checkbox
              v-decorator="[
                'agree',
                {
                  rules: [
                    {
                      // eslint-disable-next-line vue/this-in-template
                      validator: this.readIt,
                      message: 'Please complete this required field.',
                    },
                  ],
                },
              ]"
              class="feedback_input"
              size="large"
            >
              <span class="desc"> I agree to receive other communications.</span>
            </a-checkbox>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="desc">
        <a-col>
          By continuing, you agree to our Terms of Use & Privacy Policy notice, in particular the purposes for which your personal data is collected.
        </a-col>
      </a-row>

      <a-form-item class="confirmContainer">
        <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { feedback } from '@/api/index'
import countryList from '@/utils/Country.json'

export default {
  name: 'Invite',
  components: {
  },
  data() {
    return {
      question_type: '',
      countryOption: [],
      codeOption: [],
      questionTypeOption: [
        {
          label: 'Sales & business enquiry (accepting & making payments)',
          value: '1'
        },
        {
          label: 'I have a problem with my transaction/account',
          value: '2'
        },
        {
          label: 'I have a problem with API/integration/tech',
          value: '3'
        }
      ],
      integrationOptions: [
        {
          label: 'API',
          value: 'API'
        },
        {
          label: 'WooCommerce Plugin',
          value: 'WooCommerce Plugin'
        },
        {
          label: 'Magento Plugin',
          value: 'Magento Plugin'
        },
        {
          label: 'N/A',
          value: 'N/A'
        }
      ],
      issueOptions: [
        {
          label: 'My payment got declined',
          value: 'My payment got declined'
        },
        {
          label: 'I have not received my payment',
          value: 'I have not received my payment'
        },
        {
          label: 'My transaction status',
          value: 'My transaction status'
        },
        {
          label: 'Regarding KYB/KYC',
          value: 'Regarding KYB/KYC'
        },
        {
          label: 'Account-related problems',
          value: 'Account-related problems'
        },
        {
          label: 'Dispute and refunds',
          value: 'Dispute and refunds'
        },
        {
          label: 'Others',
          value: 'Others'
        }
      ],
      localOrInternationalOptions: [
        {
          'label': 'Domestic Transactions Only',
          'value': 'Domestic Transactions Only'
        },
        {
          'label': 'International Transactions Only',
          'value': 'International Transactions Only'
        },
        {
          'label': 'Both Domestic & International Transactions',
          'value': 'Both Domestic & International Transactions'
        }
      ],
      averageMonthlyVolumeOptions: [
        {
          'label': 'Just Starting Out',
          'value': 'Just Starting Out'
        },
        {
          'label': '$0 - $20,000',
          'value': '$0 - $20,000'
        },
        {
          'label': '$20,001 - $50,000',
          'value': '$20,001 - $50,000'
        },
        {
          'label': '$50,001 - $100,000',
          'value': '$50,001 - $100,000'
        },
        {
          'label': '$100,001 - $ 500,000',
          'value': '$100,001 - $ 500,000'
        },
        {
          'label': '$500,001 - $ 1,000,000',
          'value': '$500,001 - $ 1,000,000'
        },
        {
          'label': 'More than $ 1,000,000',
          'value': 'More than $ 1,000,000'
        },
        {
          'label': 'N/A',
          'value': 'N/A'
        }
      ],
      companyIndustryOptions: [
        {
          'label': 'B2C eCommerce',
          'value': 'B2C eCommerce'
        },
        {
          'label': 'B2B eCommerce',
          'value': 'B2B eCommerce'
        },
        {
          'label': 'Travel',
          'value': 'Travel'
        },
        {
          'label': 'Visa Application',
          'value': 'Visa Application'
        },
        {
          'label': 'Gaming',
          'value': 'Gaming'
        },
        {
          'label': 'Education Technology/E-Learning',
          'value': 'Education Technology/E-Learning'
        },
        {
          'label': 'SaaS',
          'value': 'SaaS'
        },
        {
          'label': 'Digital Marketing',
          'value': 'Digital Marketing'
        },
        {
          'label': 'IT Services',
          'value': 'IT Services'
        },
        {
          'label': 'Professional Services',
          'value': 'Professional Services'
        },
        {
          'label': 'Others',
          'value': 'Others'
        },
        {
          'label': 'Finance/Fintech',
          'value': 'Finance/Fintech'
        },
        {
          'label': 'Crypto',
          'value': 'Crypto'
        },
        {
          'label': 'Ad Platform',
          'value': 'Ad Platform'
        }
      ],
      form: this.$form.createForm(this, { name: 'withdraw' })
    }
  },
  created() {
    this.initOptions()
  },
  methods: {
    changeQuestionType(e) {
      this.question_type = e
    },
    readIt(rule, value, callback) {
      // const { getFieldValue } = this.props.form
      if (!value) {
        callback('Please complete this required field.')
      }

      // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
      callback()
    },
    initOptions() {
      const initCode = countryList.map((item) => {
        const tmp = {
          value: item.code,
          label: `+ ${item.code} ${item.name_en}`,
          code: item.code,
          key: item.name_cn
        }
        return tmp
      })
      const initCountry = countryList.map((item) => {
        const tmp = {
          value: item.countryCode,
          label: item.name_en,
          code: item.code,
          key: item.name_cn
        }
        return tmp
      })
      this.countryOption = initCountry
      this.codeOption = initCode
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          let type_name = ''
          if (values.question_type) {
            type_name = this.questionTypeOption.find(item => item.value === values.question_type).label
          }
          let country_code = ''
          let country = ''
          if (values.country) {
            country = values.country.split('-')[2]
          }
          if (values.country_code) {
            country_code = values.country.split('-')[1]
          }
          const params = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            country: country,
            message: values.message,
            subject: values.subject,
            country_code: country_code,
            phone: country_code + values.phone,
            order_no: values.order_no,
            help_type: values.question_type,
            help_name: type_name
          }
          params.question_type = values.question_type
          if (values.question_type === '1') {
            params.website_url = values.website_url
            params.job_title = values.job_title
            params.average_monthly_volume = values.average_monthly_volume
            params.local_or_international = values.local_or_international
            params.how_to_know = values.how_to_know
            params.company_industry = values.company_industry
          }
          if (values.question_type === '2') {
            params.issue = values.issue
            params.transaction_number = values.transaction_number || ''
          }
          if (values.question_type === '3') {
            params.integration = values.integration
          }
          console.log(params)
          feedback(
            params
          ).then((res) => {
            if (res.code === 0) {
              this.$emit('close')
              this.$message.success('submit success')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.submit{
  .title{
    line-height: 36px;
    font-size: 28px;
    color: rgb(38,83,107);
    font-weight: 600;
  }
  .sub-title{
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
  }
  .form{
    margin-top: 40px;
  }
  .desc{
    color: #33475b;
    font-size: 14px;
  }
  .confirmContainer{
    width: 150px;
    margin: 15px auto;
    .goToBtn{
        display: flex;
        background: linear-gradient(263.11deg,#1fa7d9 .58%,#b2f000 144.39%);
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(226,232,240,.24), 0 4px 6px rgba(226,232,240,.5);
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        height: 50px;
        border-radius: 6px;
        width: 150px;
        box-sizing:  border-box;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
  }
}
</style>
