<template>
  <header class="header">
    <div>
      <a href="/#/" class="nuxt-link-active">
        <!-- <img class="logo" src="@/assets/feedback/logo.svg" alt="logo"> -->
      </a>
    </div>

    <div class="contact-btn" @click="open">
      Contact Us
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    open() {
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    background: linear-gradient(95.32deg,#0a2533,#004b66 151.04%,#1b8ab2);
      padding: 0.75rem 2rem;
      @media (min-width: 768px) {
        padding: 0.75rem 2.5rem;
      }
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
      cursor: pointer;
    }
    .contact-btn{
      cursor: pointer;
      font-weight: 600;
      height: 40px;
      padding: 0  15px;
      display: flex;
      justify-content: center;
      align-items: center;
      --shadow: 0px 8px 10px rgba(39, 48, 32 .6);
      box-shadow: 0 0 transparent,
      0 0 transparent,
      var(--shadow);
      font-weight: 600;
      font-size: 14px;
      background-image: linear-gradient(to right, #b2f000 ,#1fa7d9 );
      box-sizing: border-box;
      border-radius: 8px;
      @media (min-width: 768px) {
        font-size: 16px;
        height: 50px;
        padding: 0  30px;
        background-image: linear-gradient(to right, #b2f000,#1fa7d9);
      }
    }
}
</style>
